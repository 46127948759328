#intro {
  background: url('../img/Family2.png') no-repeat center center;
  background-size: cover;
}

.top-nav-collapse {
  background-color: #677635 !important;
}

.navbar:not(.top-nav-collapse) {
  background: #ffffff !important;
}

.navbar:not(.top-nav-collapse).navbar-light .navbar-brand {
  color: black !important;
}

.navbar.top-nav-collapse.navbar-light .navbar-brand {
  color: white !important;
}

.navbar.top-nav-collapse.navbar-light:not(.top-nav-collapse) .navbar-brand {
  color: black !important;
}

.navbar.navbar-light.top-nav-collapse .breadcrumb .nav-item .nav-link {
  color: white !important;
}

.navbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: white !important;
}

.navbar.navbar-ligh:not(.top-nav-collapse) .breadcrumb .nav-item .nav-link,
.navbar.navbar-light:not(.top-nav-collapse) .navbar-nav .nav-item .nav-link {
  color: #525e2a !important;
}

.navbar.navbar-light.top-nav-collapse .breadcrumb .nav-item.active > .nav-link:hover,
.navbar.navbar-light .navbar-nav .nav-item.active > .nav-link:hover,
.navbar.navbar-light .navbar-toggler,
.navbar.navbar-light form .form-control {
  color: white !important;
}

.navbar-toggler-icon.custom-color {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

@media (max-width: 768px) {
  .navbar:not(.top-nav-collapse) {
    background: #677635 !important;
    color: white !important;
  }
  .navbar:not(.top-nav-collapse).navbar-light .navbar-brand {
    color: white !important;
  }
  .navbar.navbar-light:not(.top-nav-collapse) .navbar-nav .nav-item .nav-link {
    color: white !important;
  }
}

@media (min-width: 769px) {
  .navbar-toggler-icon.custom-color {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }

  .navbar:not(.top-nav-collapse) .navbar-toggler-icon.custom-color {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }
}

#intro .h6 {
  font-weight: 300;
  line-height: 1.7;
}

.hm-gradient .full-bg-img {
  background: rgba(196, 171, 160);
  background: linear-gradient(45deg, rgba(196, 171, 160), rgba(243, 238, 236, 0.3) 100%);
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-wrapper .errorMsg {
  color: red;
  font-weight: bold;
}

@media (max-width: 450px) {
  .margins {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

#pricing .lead {
  opacity: 0.7;
}

#pricing .card-image {
  background: url('../img/architecture.jpg') no-repeat center center;
}

#pricing ul li {
  font-size: 1.1em;
}

#antecedentes .form-check-label {
  font-weight: 400;
}
